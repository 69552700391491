.planejadores-container {
    width: 80%;
    margin: 0 auto;
}

.planejadores-title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 2rem;
}

.loading-text {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
}
